// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Add/adjust this in your CSS file if necessary */
body,
html {
  overflow-x: hidden; /* Prevent horizontal scrollbar */
}

@keyframes fadeSlideIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes blinkingBorder {
  0% {
    border-color: #6f1920;
  }
  50% {
    border-color: transparent;
  }
  100% {
    border-color: #6f1920;
  }
}

@keyframes productIn {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes productOut {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(100%);
  }
}

.center-item {
  text-align: -webkit-center;
}
`, "",{"version":3,"sources":["webpack://./src/modules/live/components/youtube.css"],"names":[],"mappings":"AAAA,kDAAkD;AAClD;;EAEE,kBAAkB,EAAE,iCAAiC;AACvD;;AAEA;EACE;IACE,UAAU;IACV,4BAA4B;EAC9B;EACA;IACE,UAAU;IACV,wBAAwB;EAC1B;AACF;AACA;EACE;IACE,qBAAqB;EACvB;EACA;IACE,yBAAyB;EAC3B;EACA;IACE,qBAAqB;EACvB;AACF;;AAEA;EACE;IACE,UAAU;IACV,2BAA2B;EAC7B;EACA;IACE,UAAU;IACV,wBAAwB;EAC1B;AACF;;AAEA;EACE;IACE,UAAU;IACV,wBAAwB;EAC1B;EACA;IACE,UAAU;IACV,2BAA2B;EAC7B;AACF;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":["/* Add/adjust this in your CSS file if necessary */\nbody,\nhtml {\n  overflow-x: hidden; /* Prevent horizontal scrollbar */\n}\n\n@keyframes fadeSlideIn {\n  from {\n    opacity: 0;\n    transform: translateY(-20px);\n  }\n  to {\n    opacity: 1;\n    transform: translateY(0);\n  }\n}\n@keyframes blinkingBorder {\n  0% {\n    border-color: #6f1920;\n  }\n  50% {\n    border-color: transparent;\n  }\n  100% {\n    border-color: #6f1920;\n  }\n}\n\n@keyframes productIn {\n  0% {\n    opacity: 0;\n    transform: translateY(100%);\n  }\n  100% {\n    opacity: 1;\n    transform: translateY(0);\n  }\n}\n\n@keyframes productOut {\n  0% {\n    opacity: 1;\n    transform: translateY(0);\n  }\n  100% {\n    opacity: 0;\n    transform: translateY(100%);\n  }\n}\n\n.center-item {\n  text-align: -webkit-center;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
